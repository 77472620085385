/* eslint-disable prefer-const */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanActivateViaAuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./pages/main/main.module').then((m) => m.MainModule)
	},
	{
		path: 'dashboard',
		loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
		canActivate: [CanActivateViaAuthGuard]
	},
	{
		path: '**',
		loadChildren: () => import('./pages/main/notfound-page/notfound-page.module').then((m) => m.NotFoundPageModule)
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
