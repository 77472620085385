import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DashboardLayoutComponent } from './dashboard-layout.component';
import { NgbDropdownModule, NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { InlineSVGModule } from 'ng-inline-svg-2';

@NgModule({
	declarations: [DashboardLayoutComponent],
	imports: [CommonModule, RouterModule, NgbDropdownModule, NgbProgressbarModule, NgbTooltipModule, InlineSVGModule],
	exports: [DashboardLayoutComponent]
})
export class DashboardLayoutModule {}
