import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-dashboard-layout',
	templateUrl: './dashboard-layout.component.html',
	styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit {
	constructor(private _router: Router) {}

	ngOnInit(): void {}

	redirectAnalytics() {
		const url = 'https://analytics.google.com/analytics/web/';
		window.open(url, '_blank');
		console.log('redirect analitycs');
	}

	logout() {
		localStorage.removeItem('user');
		this._router.navigateByUrl('/');
	}
}
