import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainLayoutModule } from './commons/layouts/main-layout/main-layout.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DashboardLayoutModule } from './commons/layouts/dashboard-layout/dashboard-layout.module';
import { CanActivateViaAuthGuard } from './core/guards/auth.guard';
import { AuthInterceptorService } from './core/interceptors/authorization.interceptor';

@NgModule({
	declarations: [AppComponent],
	imports: [BrowserModule, MainLayoutModule, DashboardLayoutModule, AppRoutingModule, NgbModule, HttpClientModule],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	providers: [
		CanActivateViaAuthGuard,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptorService,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
