import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class CanActivateViaAuthGuard implements CanActivate {
	constructor(private _authService: AuthService, private _router: Router) {}
	canActivate() {
		// If the user is not logged in we'll send them back to the home page
		if (this._authService.isLogged() == null) {
			this._router.navigate(['/']);
			return false;
		}

		return true;
	}
}
