/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable({
	providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
	constructor(private _authService: AuthService, private _router: Router) {}

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		const userLogin = this._authService.isLogged();
		let request = req;
		request = req.clone({
			withCredentials: userLogin ? true : false,
			setHeaders: {
				'Access-Control-Allow-Origin': '*',
				'Referrer-Policy': 'strict-origin-when-cross-origin'
			}
		});
		return next.handle(request).pipe(catchError((error: HttpErrorResponse) => this.errorHttp(error, userLogin)));
	}

	errorHttp(response: HttpErrorResponse, userLogin: any) {
		if (response.status === 401 && userLogin !== null) {
			this._router.navigateByUrl('/');
		}

		return throwError(response);
	}
}
