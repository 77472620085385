<div class="container__layout">
	<div class="container__layout__sidebar">
		<div class="container__layout__sidebar__header">
			<span [inlineSVG]="'./assets/icons/logos/Jockey.svg'"></span>
		</div>
		<div class="container__layout__sidebar__body">
			<div class="container__layout__sidebar__body__menu active">
				<span [inlineSVG]="'./assets/icons/menu/home-2.svg'"></span>
				<span>Inicio</span>
			</div>

			<div class="container__layout__sidebar__body__menu" (click)="redirectAnalytics()">
				<span [inlineSVG]="'./assets/icons/menu/chart.svg'"></span>
				<span>Analytics</span>
			</div>
		</div>
		<div class="container__layout__sidebar__footer">
			<div class="container__layout__sidebar__footer__icon" (click)="logout()">
				<span [inlineSVG]="'./assets/icons/menu/logout.svg'"></span>
				<label>Salir</label>
			</div>
		</div>
	</div>
	<div class="container__layout__contend">
		<section>
			<router-outlet></router-outlet>
		</section>
	</div>
</div>
