import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IRequestLogin, IResponseAuth } from '../interfaces/auth.interface';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {
	constructor(private _httpClient: HttpClient) {}

	URL_BACKEND = environment.api_url + '/api';
	loginWithEmail(data: IRequestLogin): Observable<IResponseAuth> {
		const url = `${this.URL_BACKEND}/user/customer/login`;
		data.PermanecerConectado = true;
		return this._httpClient.post<IResponseAuth>(url, { ...data });
	}

	isLogged() {
		const info = localStorage.getItem('user');
		return info ? true : null;
	}
}
